<template>
  <div>
    <div class="header-layout">
      <nav>
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/company">公司介绍</router-link>
          </li>
          <li>
            <router-link to="/about">软件介绍</router-link>
          </li>
        </ul>
      </nav>
      <router-view></router-view>
    </div>
    <div class="city">
      <span>电话:18600901547</span>
      <span>地址:北京市海淀区丰慧中路7号新材料创业大厦6层616</span>
      <span>邮箱:39424788@qq.com</span>
    </div>
    <footer>
      <div>
        <a href="https://beian.miit.gov.cn/">京ICP备2021010061号-4</a>
      </div>
    </footer>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.header-layout {
  /* width: 150%; */
  height: 100vh;
  background-image: url("../assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header-layout nav {
  width: 100%;
  height: 100px;
  padding-left: 100px;
  box-sizing: border-box;
}

.header-layout nav ul {
  list-style: none;
  height: 100%;
  display: flex;
}

.header-layout nav ul li {
  width: 230px;
  height: 100%;
  text-align: center;
  line-height: 100px;
}
.header-layout nav ul li a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.header-layout nav ul li a:hover {
  font-weight: bold;
}
footer {
  /* position: fixed; */
  width: 100%;
  bottom: 0px;
  /* height: 30px; */
}
footer div {
  display: flex;
  height: 100%;
  flex: 1;
  /* background: blue; */
  align-items: center;
  justify-content: center;
}
.city {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.city span {
  font-size: 14px;
}
footer div a {
  color: #000;
  font-size: 15px;
}
</style>